import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/LayoutMDX.jsx";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Address from "../components/Address";
import Telephone from "../components/Telephone";
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        domain
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO" />
    <h1>{`Datenschutzerklärung`}</h1>
    <h2>{`1. Datenschutz auf einen Blick`}</h2>
    <h3>{`Allgemeine Hinweise`}</h3>
    <p>{`Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
identifiziert werden können. Ausführliche Informationen zum Thema
Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
Datenschutzerklärung.`}</p>
    <h3>{`Datenerfassung auf dieser Website`}</h3>
    <h4>{`Wer ist verantwortlich für die Datenerfassung auf dieser Website?`}</h4>
    <p>{`Die Datenverarbeitung auf dieser Website erfolgt durch den
Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis
zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.`}</p>
    <h4>{`Wie erfassen wir Ihre Daten?`}</h4>
    <p>{`Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
Kontaktformular eingeben.`}</p>
    <p>{`Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
der Website durch unsere IT-Systeme erfasst. Das sind vor allem
technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
Sie diese Website betreten.`}</p>
    <h4>{`Wofür nutzen wir Ihre Daten?`}</h4>
    <p>{`Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
Website zu gewährleisten. Andere Daten können zur Analyse Ihres
Nutzerverhaltens verwendet werden.`}</p>
    <h4>{`Welche Rechte haben Sie bezüglich Ihrer Daten?`}</h4>
    <p>{`Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.`}</p>
    <p>{`Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
jederzeit an uns wenden.`}</p>
    <h2>{`2. Hosting und Content Delivery Networks (CDN)`}</h2>
    <h3>{`Externes Hosting`}</h3>
    <p>{`Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
Die personenbezogenen Daten, die auf dieser Website erfasst werden,
werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
die über eine Website generiert werden, handeln.`}</p>
    <p>{`Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
Bereitstellung unseres Online-Angebots durch einen professionellen
Anbieter (Art. 6 Abs. 1 lit. f DSGVO).`}</p>
    <p>{`Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
Erfüllung seiner Leistungspflichten erforderlich ist und unsere
Weisungen in Bezug auf diese Daten befolgen.`}</p>
    <p>{`Für das Hosting unserer Webseite verwenden wir momentan die Dienste von `}<a parentName="p" {...{
        "href": "https://www.netlify.com"
      }}>{`www.netlify.com`}</a>{` (Netlify, Inc., 2325 3rd Street, Suite 215, San Francisco, 94107 CA). Die Server von Netlify befinden sich in den USA. Deshalb werden Ihre Daten, die für den Aufruf der Webseite benötigt werden (Zugriffsdaten), in die USA übermittelt. Rechtsgrundlage für die Übermittlung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.`}</p>
    <p>{`Um den Schutz Ihrer Persönlichkeitsrechte auch im Rahmen dieser Datenübertragungen zu gewährleisten, bedienen wir uns bei der Ausgestaltung der Vertragsverhältnisse mit Netlify der Standardvertragsklauseln der EU-Kommission gemäß Art. 46 Abs. 2 lit. c) DSGVO. Diese sind unter `}<a parentName="p" {...{
        "href": "https://www.netlify.com/legal/netlify-dpa.pdf"
      }}>{`https://www.netlify.com/legal/netlify-dpa.pdf`}</a>{` abrufbar, alternativ können Sie diese Dokumente auch bei uns unter den unten angegebenen Kontaktmöglichkeiten erfragen.`}</p>
    <p><strong parentName="p">{`Sie können gegen die Verarbeitung jederzeit Widerspruch einlegen. Beachten sie hierzu die Hinweise zu Ihrem Widerspruchsrecht unter Punkt 3 dieser Datenschutzerklärung. Sie können sich mit Ihrem Widerspruch sowohl an uns als auch direkt an Netlify wenden.`}</strong></p>
    <h4>{`Abschluss eines Vertrages über Auftragsverarbeitung`}</h4>
    <p>{`Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
einen Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.`}</p>
    <h2>{`3. Allgemeine Hinweise und Pflichtinformationen`}</h2>
    <h3>{`Datenschutz`}</h3>
    <p>{`Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
Datenschutzerklärung.`}</p>
    <p>{`Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
persönlich identifiziert werden können. Die vorliegende
Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.`}</p>
    <p>{`Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
möglich.`}</p>
    <h3>{`Hinweis zur verantwortlichen Stelle`}</h3>
    <p>{`Verantwortliche Stelle ist die natürliche oder juristische Person, die
allein oder gemeinsam mit anderen über die Zwecke und Mittel der
Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
o. Ä.) entscheidet.`}</p>
    <p>{`Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
ist:`}</p>
    <Address showCompanyType mdxType="Address" />
    <h4>{`Telefon`}</h4>
    <Telephone mdxType="Telephone" />
    <h4>{`E-Mail`}</h4>
    <a href={"mailto:datenschutz@" + props.data.site.siteMetadata.domain}>
  {"datenschutz@" + props.data.site.siteMetadata.domain}
    </a>
    <h3>{`Speicherdauer`}</h3>
    <p>{`Soweit innerhalb dieser Datenschutzerklärung keine speziellere
Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
keinen anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
Fortfall dieser Gründe.`}</p>
    <h3>{`Hinweis zur Datenweitergabe in die USA`}</h3>
    <p>{`Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in
den USA eingebunden. Wenn diese Tools aktiv sind, können Ihre
personenbezogenen Daten an die US-Server der jeweiligen Unternehmen
weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer
Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind
dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden
herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich
vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass
US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten
zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.`}</p>
    <h3>{`Widerruf Ihrer Einwilligung zur Datenverarbeitung`}</h3>
    <p>{`Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
Datenverarbeitung bleibt vom Widerruf unberührt.`}</p>
    <h3>{`Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)`}</h3>
    <p><strong parentName="p">{`WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).`}</strong></p>
    <p><strong parentName="p">{`WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).`}</strong></p>
    <h3>{`Beschwerderecht bei der zuständigen Aufsichtsbehörde`}</h3>
    <p>{`Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
Rechtsbehelfe.`}</p>
    <h3>{`Recht auf Datenübertragbarkeit`}</h3>
    <p>{`Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
machbar ist.`}</p>
    <h3>{`SSL- bzw. TLS-Verschlüsselung`}</h3>
    <p>{`Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
und an dem Schloss-Symbol in Ihrer Browserzeile.`}</p>
    <p>{`Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.`}</p>
    <h3>{`Auskunft, Löschung und Berichtigung`}</h3>
    <p>{`Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
Daten können Sie sich jederzeit an uns wenden.`}</p>
    <h3>{`Recht auf Einschränkung der Verarbeitung`}</h3>
    <p>{`Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
folgenden Fällen:`}</p>
    <ul>
      <li parentName="ul">{`Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
Daten zu verlangen.`}</li>
      <li parentName="ul">{`Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
geschah/geschieht, können Sie statt der Löschung die Einschränkung
der Datenverarbeitung verlangen.`}</li>
      <li parentName="ul">{`Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
jedoch zur Ausübung, Verteidigung oder Geltendmachung von
Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
verlangen.`}</li>
      <li parentName="ul">{`Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
haben, muss eine Abwägung zwischen Ihren und unseren Interessen
vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung
Ihrer personenbezogenen Daten zu verlangen.`}</li>
    </ul>
    <p>{`Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
natürlichen oder juristischen Person oder aus Gründen eines wichtigen
öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
verarbeitet werden.`}</p>
    <h3>{`Widerspruch gegen Werbe-E-Mails`}</h3>
    <p>{`Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
Werbung und Informationsmaterialien wird hiermit widersprochen. Die
Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
Spam-E-Mails, vor.`}</p>
    <h2>{`4. Datenerfassung auf dieser Website`}</h2>
    <h3>{`Server-Log-Dateien`}</h3>
    <p>{`Der Provider der Seiten erhebt und speichert automatisch Informationen
in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
übermittelt. Dies sind:`}</p>
    <ul>
      <li parentName="ul">{`Browsertyp und Browserversion`}</li>
      <li parentName="ul">{`verwendetes Betriebssystem`}</li>
      <li parentName="ul">{`Referrer URL`}</li>
      <li parentName="ul">{`Hostname des zugreifenden Rechners`}</li>
      <li parentName="ul">{`Uhrzeit der Serveranfrage`}</li>
      <li parentName="ul">{`IP-Adresse`}</li>
    </ul>
    <p>{`Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
vorgenommen.`}</p>
    <p>{`Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
technisch fehlerfreien Darstellung und der Optimierung seiner Website –
hierzu müssen die Server-Log-Files erfasst werden.`}</p>
    <h3>{`Kontaktformular`}</h3>
    <p>{`Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
Ihre Einwilligung weiter.`}</p>
    <p>{`Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.`}</p>
    <p>{`Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.`}</p>
    <h3>{`Anfrage per E-Mail, Telefon oder Telefax`}</h3>
    <p>{`Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
(Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
Einwilligung weiter.`}</p>
    <p>{`Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.`}</p>
    <p>{`Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
(z.B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
– bleiben unberührt.`}</p>
    <h2>{`5. Soziale Medien`}</h2>
    <h3>{`Social-Media-Plugins mit Shariff`}</h3>
    <p>{`Auf dieser Website werden Plugins von sozialen Medien verwendet (z.B.
Facebook, Twitter, Instagram, Pinterest, XING, LinkedIn, Tumblr).`}</p>
    <p>{`Die Plugins können Sie in der Regel anhand der jeweiligen
Social-Media-Logos erkennen. Um den Datenschutz auf dieser Website zu
gewährleisten, verwenden wir diese Plugins nur zusammen mit der
sogenannten „Shariff“-Lösung. Diese Anwendung verhindert, dass die auf
dieser Website integrierten Plugins Daten schon beim ersten Betreten der
Seite an den jeweiligen Anbieter übertragen.`}</p>
    <p>{`Erst wenn Sie das jeweilige Plugin durch Anklicken der zugehörigen
Schaltfläche aktivieren, wird eine direkte Verbindung zum Server des
Anbieters hergestellt (Einwilligung). Sobald Sie das Plugin aktivieren,
erhält der jeweilige Anbieter die Information, dass Sie mit Ihrer
IP-Adresse dieser Website besucht haben. Wenn Sie gleichzeitig in Ihrem
jeweiligen Social-Media-Account (z.B. Facebook) eingeloggt sind, kann
der jeweilige Anbieter den Besuch dieser Website Ihrem Benutzerkonto
zuordnen.`}</p>
    <p>{`Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art. 6
Abs. 1 lit. a DSGVO dar. Diese Einwilligung können Sie jederzeit mit
Wirkung für die Zukunft widerrufen.`}</p>
    <h2>{`6. Plugins und Tools`}</h2>
    <h3>{`YouTube mit erweitertem Datenschutz`}</h3>
    <p>{`Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
die Google Ireland Limited („Google“), Gordon House, Barrow Street,
Dublin 4, Irland.`}</p>
    <p>{`Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt
laut YouTube, dass YouTube keine Informationen über die Besucher auf
dieser Website speichert, bevor diese sich das Video ansehen. Die
Weitergabe von Daten an YouTube-Partner wird durch den erweiterten
Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So stellt
YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine
Verbindung zum Google DoubleClick-Netzwerk her.`}</p>
    <p>{`Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn
Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube,
Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account
ausloggen.`}</p>
    <p>{`Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies
auf Ihrem Endgerät speichern oder vergleichbare
Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen. Auf
diese Weise kann YouTube Informationen über Besucher dieser Website
erhalten. Diese Informationen werden u. a. verwendet, um
Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern
und Betrugsversuchen vorzubeugen.`}</p>
    <p>{`Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen Einfluss
haben.`}</p>
    <p>{`Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
Einwilligung ist jederzeit widerrufbar.`}</p>
    <p>{`Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
Datenschutzerklärung unter: `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy?hl=de"
      }}>{`https://policies.google.com/privacy?hl=de`}</a>{`.`}</p>
    <h3>{`Vimeo ohne Tracking (Do-Not-Track)`}</h3>
    <p>{`Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die
Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.`}</p>
    <p>{`Wenn Sie eine unserer mit Vimeo-Videos ausgestatteten Seiten besuchen,
wird eine Verbindung zu den Servern von Vimeo hergestellt. Dabei wird
dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
Zudem erlangt Vimeo Ihre IP-Adresse. Wir haben Vimeo jedoch so
eingestellt, dass Vimeo Ihre Nutzeraktivitäten nicht nachverfolgen und
keine Cookies setzen wird.`}</p>
    <p>{`Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
Einwilligung ist jederzeit widerrufbar.`}</p>
    <p>{`Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
der EU-Kommission sowie nach Aussage von Vimeo auf „berechtigte
Geschäftsinteressen“ gestützt. Details finden Sie hier:
`}<a parentName="p" {...{
        "href": "https://vimeo.com/privacy"
      }}>{`https://vimeo.com/privacy`}</a>{`.`}</p>
    <p>{`Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
Datenschutzerklärung von Vimeo unter: `}<a parentName="p" {...{
        "href": "https://vimeo.com/privacy"
      }}>{`https://vimeo.com/privacy`}</a>{`.`}</p>
    <h3>{`OpenStreetMap`}</h3>
    <p>{`Wir nutzen den Kartendienst von OpenStreetMap (OSM). Anbieterin ist die
Open-Street-Map Foundation (OSMF), 132 Maney Hill Road, Sutton
Coldfield, West Midlands, B72 1JU, United Kingdom.`}</p>
    <p>{`Wenn Sie eine Website besuchen, auf der OpenStreetMap eingebunden ist,
werden u. a. Ihre IP-Adresse und weitere Informationen über Ihr
Verhalten auf dieser Website an die OSMF weitergeleitet. OpenStreetMap
speichert hierzu unter Umständen Cookies in Ihrem Browser oder setzt
vergleichbare Wiedererkennungstechnologien ein.`}</p>
    <p>{`Ferner kann Ihr Standort erfasst werden, wenn Sie dies in Ihren
Geräteeinstellungen – z.B. auf Ihrem Handy – zugelassen haben. Der
Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
Details entnehmen Sie der Datenschutzerklärung von OpenStreetMap unter
folgendem Link: `}<a parentName="p" {...{
        "href": "https://wiki.osmfoundation.org/wiki/Privacy_Policy"
      }}>{`https://wiki.osmfoundation.org/wiki/Privacy_Policy`}</a>{`.`}</p>
    <p>{`Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden
Darstellung unserer Online-Angebote und einer leichten Auffindbarkeit
der von uns auf der Website angegebenen Orte. Dies stellt ein
berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
Einwilligung ist jederzeit widerrufbar.`}</p>
    <p>{`Quelle: `}<a parentName="p" {...{
        "href": "https://www.e-recht24.de/"
      }}>{`e-recht24.de`}</a></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      